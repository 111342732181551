import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Container } from "reactstrap"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CheckoutForm from "../components/checkout/CheckoutForm"

// https://github.com/stripe/react-stripe-elements/blob/master/README.md#server-side-rendering-ssr

const stripePromise = loadStripe(
  "pk_test_51H5EdZJ3QPNTG35W2hyAKpCxAvMwHKYCjMw829lnAPKzRcDvJqfLdfmovH1LO4FJarFWlhQVxuyrFRntMZelwJig00t9G0nQAK"
)

const CheckoutPage = () => {
  return (
    <Layout location="checkout">
      <SEO title="Checkout" />
      <Container>
        <h1>Checkout</h1>
        <Elements stripe={stripePromise}>
          <div className="stripe-elements">
            <CheckoutForm />
          </div>
        </Elements>
      </Container>
    </Layout>
  )
}

export default CheckoutPage
